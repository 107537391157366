import Swiper, { Navigation, Scrollbar } from "swiper";

Swiper.use([Navigation, Scrollbar]);

const swiperInprogress = new Swiper(".inprogress__slider", {
  initialSlide: 0,
  slidesPerView: 6,

  pagination: {
    el: ".inprogress-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".inprogress-button-next",
    prevEl: ".inprogress-button-prev",
  },
  breakpoints: {
    // Адаптивные настройки
    320: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    480: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 6,
      spaceBetween: 10,
    },
    1200: {
      slidesPerView: 6,
      spaceBetween: 10,
    },
  },
});
