class Modal {
  constructor(options) {
    this.options = {
      isOpen: () => {},
      isClose: () => {},
      ...options
    };
    this.modal = document.querySelector(".modal");
    this.fixBlocks = document.querySelectorAll(".fix-block");
    this.focusElements = "a[href], input, button, select, textarea, [tabindex]";
    this.isOpen = false;
    this.modalContainer = null;
    this.previousActiveElement = null;

    this.initEvents();
  }

  initEvents() {
    document.addEventListener("click", (e) => this.handleDocumentClick(e));
    window.addEventListener("keydown", (e) => this.handleKeyDown(e));
    this.modal.addEventListener("click", (e) => this.handleModalClick(e));
  }

  handleDocumentClick(e) {
    const clickedElement = e.target.closest("[data-path]");
    if (clickedElement) {
      this.prepareModal(clickedElement);
      this.open();
      return;
    }

    if (e.target.closest(".modal-close")) {
      this.close();
    }
  }

  prepareModal(clickedElement) {
    let target = clickedElement.dataset.path;
    let animation = clickedElement.dataset.animation || "fade";
    let speed = parseInt(clickedElement.dataset.speed) || 300;

    this.modalContainer = document.querySelector(`[data-target="${target}"]`);
    this.modal.style.setProperty("--transition-time", `${speed / 1000}s`);
    this.modalContainer.classList.add(animation);
  }

  handleKeyDown(e) {
    if (e.keyCode === 27 && this.isOpen) { // ESC key
      this.close();
    }
    if (e.keyCode === 9 && this.isOpen) { // Tab key
      this.focusCatch(e);
    }
  }

  handleModalClick(e) {
    if (!e.target.closest(".modal__container") && this.isOpen) {
      this.close();
    }
  }

  open() {
    this.previousActiveElement = document.activeElement;
    this.modal.classList.add("is-open");
    this.modalContainer.classList.add("modal-open", "animate-open");
    this.disableScroll();
    this.isOpen = true;
    this.focusTrap();

    setTimeout(() => this.options.isOpen(this), parseInt(this.modal.style.getPropertyValue("--transition-time")) * 1000);
  }

  close() {
    this.modalContainer.classList.remove("animate-open");
    this.modal.classList.remove("is-open");
    this.modalContainer.classList.remove("modal-open");
    this.enableScroll();
    this.isOpen = false;
    this.focusTrap();
    this.options.isClose(this);
  }

  focusCatch(e) {
    const focusables = [...this.modalContainer.querySelectorAll(this.focusElements)];
    const index = focusables.indexOf(document.activeElement);

    if (e.shiftKey && index === 0) {
      focusables[focusables.length - 1].focus();
      e.preventDefault();
    } else if (!e.shiftKey && index === focusables.length - 1) {
      focusables[0].focus();
      e.preventDefault();
    }
  }

  focusTrap() {
    const focusables = this.modalContainer.querySelectorAll(this.focusElements);
    this.isOpen ? focusables[0]?.focus() : this.previousActiveElement?.focus();
  }

  disableScroll() {
    const pagePosition = window.scrollY;
    document.body.style.cssText = `position: fixed; top: -${pagePosition}px; width: 100%;`;
    this.lockPadding();
  }

  enableScroll() {
    const pagePosition = parseInt(document.body.style.top);
    document.body.style.cssText = "";
    window.scroll({ top: -pagePosition, left: 0 });
    this.unlockPadding();
  }

  lockPadding() {
    const paddingOffset = `${window.innerWidth - document.body.offsetWidth}px`;
    this.fixBlocks.forEach(el => el.style.paddingRight = paddingOffset);
    document.body.style.paddingRight = paddingOffset;
  }

  unlockPadding() {
    this.fixBlocks.forEach(el => el.style.paddingRight = "");
    document.body.style.paddingRight = "";
  }
}

const modal = new Modal({
  isOpen: (modal) => console.log("Modal opened", modal),
  isClose: () => console.log("Modal closed"),
});
