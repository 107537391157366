import { disableScroll } from '../functions/disable-scroll';
import { enableScroll } from '../functions/enable-scroll';

(function() {
  const burger = document?.querySelector('[data-burger]');
  const menu = document?.querySelector('[data-menu]');
  const overlay = document?.querySelector('[data-menu-overlay]');

  function toggleMenu() {
    const isActive = menu?.classList.contains('menu--active');
    burger?.classList.toggle('burger--active', !isActive);
    menu?.classList.toggle('menu--active', !isActive);
    burger?.setAttribute('aria-expanded', !isActive);
    burger?.setAttribute('aria-label', isActive ? 'Открыть меню' : 'Закрыть меню');

    if (!isActive) {
      disableScroll();
    } else {
      enableScroll();
    }
  }

  function closeMenuIfOutsideClick(e) {
    if (menu?.classList.contains('menu--active') && !menu.contains(e.target) && !burger.contains(e.target)) {
      toggleMenu();
    }
  }

  function initEventListeners() {
    burger?.addEventListener('click', toggleMenu);
    overlay?.addEventListener('click', toggleMenu);

    // Закрытие меню при клике вне его
    document.addEventListener('click', closeMenuIfOutsideClick);

    // Закрытие меню при клике на элементы меню
    menu?.addEventListener('click', (e) => {
      if (e.target.hasAttribute('data-menu-item')) {
        toggleMenu();
      }
    });
  }

  initEventListeners();
})();
