import Swiper, { Navigation, Scrollbar } from "swiper";

Swiper.use([Navigation, Scrollbar]);

const swiperNew = new Swiper(".newbook__slider", {
  initialSlide: 0,
  slidesPerView: 6,
  pagination: {
    el: ".newbook-pagination",
    clickable: true,
  },
  navigation: {
    nextEl: ".newbook-button-next",
    prevEl: ".newbook-button-prev",
  },
  breakpoints: {
    // Адаптивные настройки
    320: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    480: {
      slidesPerView: 2,
      spaceBetween: 10,
    },
    768: {
      slidesPerView: 4,
      spaceBetween: 10,
    },
    1024: {
      slidesPerView: 6,
      spaceBetween: 10,
    },
    1200: {
      slidesPerView: 6,
      spaceBetween: 10,
    },
  },
});
