document.addEventListener("DOMContentLoaded", function () {
  const tabs = document.querySelectorAll(".tab");
  const contents = document.querySelectorAll(".tab-content");
  const indicator = document.querySelector(".tab-indicator");

  // Функция для обновления позиции и размера индикатора
  function updateIndicator() {
    const activeTab = document.querySelector(".tab.active");
    // Проверка, что активный таб и индикатор существуют
    if (activeTab && indicator) {
      indicator.style.width = `${activeTab.offsetWidth}px`;
      indicator.style.left = `${activeTab.offsetLeft}px`;
    }
  }

  // Обработчик событий для клика на табы
  tabs.forEach((tab) => {
    tab.addEventListener("click", () => {
      tabs.forEach((t) => t.classList.remove("active")); // Убираем активный класс у всех табов
      tab.classList.add("active"); // Добавляем активный класс на кликнутый таб

      contents.forEach((content) => content.classList.remove("active")); // Убираем активный класс у всех контентов
      document.getElementById(tab.dataset.tab).classList.add("active"); // Активируем контент, связанный с текущим табом

      updateIndicator(); // Обновляем индикатор
    });
  });

  // Инициализация позиции индикатора при загрузке и изменении размера окна
  updateIndicator();
  window.addEventListener("resize", updateIndicator);
});
