document.addEventListener("DOMContentLoaded", function () {
  // Переменные для табов и их контента
  const profileTabs = document.querySelectorAll(".profile__item");
  const profileTabContents = document.querySelectorAll(".profile__tab");
  const profileIndicator = document.querySelector(".profile-tab-indicator");

  // Функция для обновления индикатора
  function updateProfileIndicator() {
    const activeTab = document.querySelector(".profile__item.active");
    if (activeTab && profileIndicator) {
      profileIndicator.style.width = `${activeTab.offsetWidth}px`;
      profileIndicator.style.left = `${activeTab.offsetLeft}px`;
    }
  }

  // Обработчик клика по табам
  profileTabs.forEach((tab) => {
    tab.addEventListener("click", function () {
      // Удаляем активные классы у всех табов и контента
      profileTabs.forEach((item) => item.classList.remove("active"));
      profileTabContents.forEach((content) =>
        content.classList.remove("active")
      );

      // Добавляем активный класс на текущий таб и его контент
      this.classList.add("active");
      document.getElementById(this.dataset.tab).classList.add("active");

      // Обновляем индикатор
      updateProfileIndicator();
    });
  });

  // Инициализация индикатора при загрузке
  updateProfileIndicator();
  window.addEventListener("resize", updateProfileIndicator); // Корректировка при изменении размера окна
});
